<template>
  <div class="hello">
    <h1>Здесь будут отображаться новости</h1>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data () {
    return {

    }
  }
}
</script>

<style scoped>

</style>
